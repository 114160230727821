@import '~scss/mixins';

/* font size props */
html {
	@include size(100%);
	overflow: hidden;

	font-size: 15px;
}

body {
	@include size(100%);
	overflow-x: hidden;
	overflow-y: scroll;

	box-sizing: content-box; /* So the width will be 100% + 17px */
	padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
}

@media only screen and (max-width: 480px) {
	html {
		font-size: 10px;
	}
}

@media only screen and (max-width: 540px) {
	html {
		font-size: 12px;
	}
}

@media only screen and (max-width: 800px) {
	html {
		font-size: 14px;
	}
}

@media only screen and (min-width: 1440px) {
	html {
		font-size: 16px;
	}
}

@media only screen and (min-width: 1920px) {
	html {
		font-size: 20px;
	}
}

@media only screen and (min-width: 2304px) {
	html {
		font-size: 24px;
	}
}

@media only screen and (min-width: 2560px) {
	html {
		font-size: 26px;
	}
}

@media only screen and (min-width: 3200px) {
	html {
		font-size: 36px;
	}
}

@media only screen and (min-width: 3840px) {
	html {
		font-size: 44px;
	}
}

/* end of font size props */
