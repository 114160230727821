@import '~scss/mixins';

.row {
	display: flex;

	padding: var(--offset) $side-space;

	&.wide {
		padding: var(--offset) var(--offset-xl);
	}
}

.col {
	display: flex;
	flex: 1;
	flex-direction: column;

	margin: 0 var(--offset);
}

.yellow-text {
	color: var(--yellow);
}

.green-text {
	color: var(--green);
}

.purple-text {
	color: var(--purple);
}

.strong {
	font-weight: 700 !important;
}

button.dm-button {
	@include size(3.75rem, fit-content);
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	min-width: 5rem;
	padding: 0 var(--offset-l);

	font: var(--font-form-element);
	line-height: 1.25rem;
	color: var(--white);
	text-transform: uppercase;

	background-color: var(--yellow);
	border: unset;
	border-radius: var(--border-radius-l);
	outline: none;

	&:disabled {
		cursor: not-allowed;

		background-color: var(--gray);
	}
}

input.dm-input {
	@include size(4rem, 10rem);
	padding: 0 2rem;

	font: var(--font-form-element);
	color: #444;

	background-color: #fff !important;
	border: unset;
	border-radius: 2rem;
	outline: none;
	box-shadow: 0 0 2rem 0 rgb(5 20 50 / 50%);
}

.btn-icon {
	@include size(1.5rem);
	margin-left: 0.75rem;
}

.decoration {
	@include size(auto, calc(100vw + 2px));
	position: absolute;
	z-index: 1;
}

.wave-shadow {
	filter: drop-shadow($wave-shadow);
}

.lopasti-shadow {
	filter: drop-shadow($lopasti-shadow);
}

.pointer {
	cursor: pointer;
}

@media only screen and (max-width: 800px) {
	.hide-small {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.row {
		padding: 1vw 2vw;
	}

	.col {
		margin: 1vw 2vw;
	}
}
