/* You can add global styles to this file, and also import other style files */
@import 'vars/colors';
@import 'vars/sizes';
@import 'vars/others';
@import 'mixins';
@import 'classes';
@import 'responsive';

html {
	@include var-colors($base-color);
	@include var-range(--offset, 1rem);
	@include var-range(--border-radius, 1rem);
	@include var-range(--transition-duration, 0.5s);

	/* font size vars */
	--font-h1: 300 2.4rem 'Open Sans', sans-serif;
	--font-h2: 400 3rem 'Averia Sans Libre', sans-serif;
	--font-h3: 700 1.5rem 'Open Sans', sans-serif;
	--font-h4: 700 1.5rem 'Open Sans', sans-serif;
	--font-h1-mobile: 300 1.5rem 'Open Sans', sans-serif;
	--font-h2-mobile: 400 3.6rem 'Averia Sans Libre', sans-serif;
	--font-h2-mobile-s: 400 2.6rem 'Averia Sans Libre', sans-serif;
	--font-h3-mobile: 700 1.7rem 'Open Sans', sans-serif;
	--font-p1: 1.3rem 'Open Sans', sans-serif;
	--font-p2: 1.15rem 'Open Sans', sans-serif;
	--font-p3: 1rem 'Open Sans', sans-serif;
	--font-p4: 0.7rem 'Open Sans', sans-serif;
	--font-card-name-l: 400 2rem 'Averia Sans Libre', sans-serif;
	--font-card-name-s: 600 0.8rem 'Averia Sans Libre', sans-serif;
	--font-form-element: 400 1.25rem 'Open Sans', sans-serif;
	--font-letter: 400 2rem caveat, sans-serif;
}

* {
	color: #fff;

	&:focus {
		outline: none;
	}
}

html,
body {
	margin: 0;
	padding: 0;
}

h1 {
	margin-block-start: var(--offset-l);
	margin-block-end: var(--offset-l);

	font: var(--font-h1);
	line-height: 3.15rem;
}

h2 {
	margin-block-start: var(--offset-m);
	margin-block-end: var(--offset-m);

	font: var(--font-h2);
	text-transform: uppercase;
}

h3 {
	margin-block-start: var(--offset);
	margin-block-end: var(--offset);

	font: var(--font-h3);
	color: var(--light-blue);
	text-transform: capitalize;
}

h4 {
	margin-block-start: 0.25rem;
	margin-block-end: 0.25rem;

	font: var(--font-h4);
}

p {
	margin-block-start: var(--offset-xs);
	margin-block-end: var(--offset-xs);

	font: var(--font-p2);

	&.small {
		margin-block-start: 0;
		margin-block-end: 0;

		font: var(--font-p3);
	}

	&.large {
		font: var(--font-p1);
	}
}

a {
	text-decoration: none;
}
