@mixin var-range($variable, $value) {
	// Default
	#{$variable}: $value;
	#{$variable}-m: calc(var(#{$variable}) * 1.5);

	// Smaller values
	#{$variable}-s: calc(var(#{$variable}) / 2);
	#{$variable}-xs: calc(var(#{$variable}) / 4);

	// Bigger values
	#{$variable}-l: calc(var(#{$variable}) * 2);
	#{$variable}-xl: calc(var(#{$variable}) * 4);
}

@mixin var-colors($base-color) {
	@each $name, $color in $base-color {
		--#{"" + $name}: #{$color};
	}
}

@mixin size($height, $width: null) {
	height: $height;
	@if $width {
		width: $width;
	} @else {
		width: $height;
	}
}

@mixin block-for-image($height: null, $width: null) {
	width: $width;
	height: $height;

	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

@mixin scroll {
	$scrollbar-size: 8px;
	$scrollbar-color: rgb(49 65 109 / 15%);
	$scrollbar-hover-color: var(--gray);
	$scrollbar-background-color: var(--light-gray);
	$scrollbar-shadow-color: rgb(190 195 208 / 50%);

	&::-webkit-scrollbar-track {
		background-color: $scrollbar-background-color;
		border-radius: $scrollbar-size;
		box-shadow: inset 0 0 6px $scrollbar-shadow-color;
	}

	&::-webkit-scrollbar {
		width: $scrollbar-size;
		height: $scrollbar-size;

		border-radius: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color;
		border-radius: 6px;
		box-shadow: unset;

		&:hover {
			background-color: $scrollbar-hover-color;
		}
	}
}

@mixin h2-mobile() {
	margin: var(--offset-m) 0;

	font: var(--font-h2-mobile);
	text-align: center;
}
